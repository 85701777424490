import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

const firebaseConfig = {

  apiKey: "AIzaSyCVda959unH2qSupu80lQhIMtxI6bkpOWw",

  authDomain: "aerbag-flutter.firebaseapp.com",

  databaseURL: "https://aerbag-flutter.firebaseio.com",

  projectId: "aerbag-flutter",

  storageBucket: "aerbag-flutter.appspot.com",

  messagingSenderId: "196600744174",

  appId: "1:196600744174:web:0b138b872ce150b4514bff",

  measurementId: "G-YYZ9Y3NHYT"

};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const genrateRecaptcha = () => {
  window.recaptchaVerifier = new RecaptchaVerifier(
    "request-recaptcha",
    {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
    },
    auth
  );
};

export { auth, genrateRecaptcha };
